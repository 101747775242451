import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2),
    },

    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
    },

    settingOption: {
        marginLeft: "auto",
    },

    margin: {
        margin: theme.spacing(1),
    },

    color: {
        color: "#0241AD"
    },

    text: {
        marginLeft: "42px",
    },

    textP: {
        marginLeft: "42px",
    },

}));

const Api = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container>
                <h2>Documentação para envio de mensagens</h2>

                <h2 className={classes.color}>Métodos de Envio</h2>

                <p className={classes.text}>1. Mensagens de Texto</p>
                <p className={classes.text}>2. Mensagens de Mídia por Link Url</p>
                <p className={classes.text}>3. Mensagens de Mídia por Base64</p>

                <h2 className={classes.color}>Instruções</h2>
                <p><b>Observações Importantes</b></p>
                <ul>
                    <li>Para pegar o token da API, vá em TOKENS que seu token estará la, sem ele não será possivel enviar mensagens.</li>
                    <li>O número para envio não deve ter mascara ou caracteres especiais e deve ser composto por:</li>
                    <br />
                    <ol>
                        <ul>
                            <li>Código do pais - Ex: 55 (Brasil)</li>
                            <li>DDD</li>
                            <li>Número</li>
                        </ul>
                    </ol>
                </ul>
                <div>
                <h2 className={classes.color}>1. Mensagens de Texto</h2>
                <p>Seguem abaixo lista de informacoes necessárias para envio das mensagens de texto:</p>
                <p className={classes.textP}><b>URL: </b>{process.env.REACT_APP_BACKEND_URL}/api/messages/send</p>
                <p className={classes.textP}><b>Metódo: </b>POST</p>
                <p className={classes.textP}><b>Headers: </b>Authorization: Bearer (token) e Content-Type application/json</p>
                <p className={classes.textP}><b>Body: </b>"number": "5599999999999", "body": "Enviado via api"</p>
                </div>
                <div>
                <h2 className={classes.color}>2. Mensagens de Mídia por Link Url</h2>
                <h4>Exemplo:</h4>
                <pre>{`
curl --location --request POST 'http://localhost:8080/api/messages/sendMediaLink 
--header 'Content-Type: application/json'
--header 'Authorization: Bearer 9cc8dde7-3dd2-488c-bb9d-e3842ae0c59b'
--data-raw '   
{
	"numbers": ["554784080000", "554791150000"],
	"header": "Segue os dados de faturamento do pedido 1122121",
	"footer": "Agradecemos a sua preferencia \nEsta é uma mensagem automática, *não precisa responder.*",
	"medias": [{
					"url": "http://localhost:8080/public/1663937253338.png",
					"caption": "Novidades"
				},
				{
					"url": "http://localhost:8080/public/10570-27-09-2022_10-13-57.pdf",
					"caption": "Boleto"
				},
				{
					"url": "http://localhost:8080/public/10570-27-09-2022_10-13-57.pdf",
					"caption": "NotaFiscal"
				}
			]	
}
'`}
                </pre>
                </div>
                <div>
                <h2 className={classes.color}>3. Mensagens de Mídia por Base64</h2>
                <h4>Exemplo:</h4>
                <pre>{`
curl --location --request POST 'http://localhost:8080/api/messages/sendMedia 
--header 'Content-Type: application/json'
--header 'Authorization: Bearer 9cc8dde7-3dd2-488c-bb9d-e3842ae0c59b'
--data-raw '   
{
	"numbers": ["554784080056"],
	"header": "Segue os dados de faturamento do pedido 1122121",
	"footer": "Agradecemos a sua preferencia Esta é uma mensagem automática, *não precisa responder.*",
	"medias": [{
					"Base64": "PD94bWwgdmVyc2lvbj0iM...",
                    "Format": "xml",
					"caption": "Novidades"
				},
				{
					"Base64": "JVBERi0xLjINCjEgMCBvYmog...",
                    "Format": "pdf",
					"caption": "Boleto"
				}
			]	
}
'`}
                </pre>
                </div>

            </Container>
        </div>
    );
};

export default Api; 
