
import { Box, Button, Card, Grid, MenuItem, styled, TextField, Checkbox, Paper } from "@material-ui/core/";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {  Formik } from "formik";
import React, { useEffect, useState, } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(8, 8, 3),
  },
  log: {

    margin: "15px",
  },
  formbox: {

  },
  formdisplay: {
    display: 'flex',
    flexDirection: 'row'

  },
  img: {
    borderRadius: "5%",
  },
  uploadInput:{
    display: "none",
  },
  formpaper: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    verticalAlign: "middle",
    marginBottom: 12,
  },
  formtextarea: {
    marginBottom: 12,
    margin: '10px',

  },
  attach: {
    display: "block"
  },
  messagearea: {
    marginBottom: 12,
    margin: '10px',
    flexDirection: 'row',
    width: '70%'

  },
  numbersarea: {
    marginBottom: 12,
    margin: '10px',
    flexDirection: 'row',
    width: '30%',
    height: '100%'

  },

  button: {
    padding: theme.spacing(2),
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    verticalAlign: "middle",
    marginBottom: 12,
    marginRight: 12,
    flexDirection: 'row',
  },

  btns: {
    marginLeft: "auto",
  },

  settingOption: {
    marginLeft: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const CampanhaSchema = Yup.object().shape({
	nomeCampanha: Yup.string()
		.min(2, "Nome Curto")
		.max(12, "Nome Longo")
		.required("Required", 'erro')
});
const InvoiceRoot = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));
;

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const StyledInput = styled(TextField)(() => ({
  minWidth: "180px !important",
  marginRight: "10px",
  "& label": { fontSize: "14px" },
  "& label.MuiInputLabel-shrink": { marginTop: "0px" },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      padding: "10px 14px !important",
    },
  },
}));
const StyledInputNumber = styled(TextField)(() => ({
  minWidth: "110px !important",
  marginRight: "10px",
  "& label": { fontSize: "14px" },
  "& label.MuiInputLabel-shrink": { marginTop: "0px" },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      padding: "10px 14px !important",
    },
  },
}));





const MassMessagesForm = ({ selectedUserIds, selectedQueueIds, selectedTmin, selectedTmax, selectedInterval, selectedConnections }) => {
  const history = useHistory();
  const classes = useStyles();
  const [connections, setConnections] = useState([]);
  const [companhas, setCampanhas] = useState([]);
  const [logs, setLogs] = useState([]);
  const [media1, setMedia1] = useState([]);
  const [media2, setMedia2] = useState([]);
  const [media3, setMedia3] = useState([]);
  const [log, setLog] = useState([]);
  const [selectedConn, setSelectedConn] = useState([]);
  const [currentConn, setCurrentConn] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/whatsapp");
        const conectionNotDefault = data.filter(
          conection => conection.isDefault !== true
        );
        setConnections(conectionNotDefault);
      } catch (err) {
        toastError("precisa de uma conexão não default para fazer o envio das mensagens")
        toastError(err);
      }
    })();
  }, []);

  const [users, setUsers] = useState([]);

  const [typeMessage, setTypeMessage] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/users");

        setUsers(data.users);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);


  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/marketing");

        setCampanhas(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, [])
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/marketinglogs");

        setLogs(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, [])

  const command = async (nameCampanha, command) => {
  //console.log(`/marketing/${nameCampanha}/${command}`)
    const data = await api.get(`/marketing/${nameCampanha}/${command}`)
    if (command === 'start') {
      toast.success("Envio iniciado!");
    }
    if (command === 'stop') {
      toast.info("Envio pausado!");
    }


    if (command === 'delete') {
      toast.warning("Companha Deletada!");
      history.go(0)
    }
    if (command === 'deleteLog') {
      toast.warning("Log Deletado!");
      history.go(0)
    }

    if (command === 'log') {
      document.getElementById('form').style.display = "none";
      document.getElementById('log').style.display = "flex";
      setLog(data.data)
      //window.alert(data.data);
    }
    if (command === 'logOld') {
      document.getElementById('form').style.display = "none";
      document.getElementById('log').style.display = "flex";
      setLog(data.data)
      //window.alert(data.data);
    }

  }
  const voltar = () => {
    document.getElementById('form').style.display = "flex";
    document.getElementById('companhas').style.display = "block";
    document.getElementById('log').style.display = "none";
    document.getElementById('logsAntigos').style.display = "none";
  }
  const viewLogsAntigos = () => {
    document.getElementById('form').style.display = "none";
    document.getElementById('log').style.display = "none";
    document.getElementById('logsAntigos').style.display = "block";
    document.getElementById('companhas').style.display = "none";
  }

  const [queues, setQueues] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");

        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);
  const handleChangeMedias = (e) => {

    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    if (e.target.id === 'message1') {
      setMedia1(selectedMedias);
    }
    if (e.target.id === 'message2') {
      setMedia2(selectedMedias);
    }
    if (e.target.id === 'message3') {
      setMedia3(selectedMedias);
    }

  };


  const handleSubmit = async (valuesData, { isSubmitting }) => {


    if (currentConn.length === 0){
      toast.error("Selecione pelo menos uma Conexão")
    } else{

      const apenasIds = valor => valor.id;
    valuesData.connections = currentConn.map(apenasIds)
    valuesData.typeMessage = typeMessage.target.value
    const formData = new FormData();
    formData.append("val", JSON.stringify(valuesData));
    media1.forEach((media) => {
      formData.append("media1", media);
      formData.append("body1", media.name);
    });
    media2.forEach((media) => {
      formData.append("media2", media);
      formData.append("body2", media.name);
    });
    media3.forEach((media) => {
      formData.append("media3", media);
      formData.append("body3", media.name);
    });



    try {
      await api.post("/marketing", formData);
      toast.success("Campanha com sucesso!");
      history.go(0)
    } catch (err) {
      toastError(err);
    }

    }
    





  };
  return (
    <InvoiceRoot>
      <Card elevation={3}>
        <div id='companhas' style={{ display: 'block' }} >

          {companhas.map(marketing => (

            <>
              <hr></hr>
              <Grid container spacing={12}>
                <Grid item >
                  <span style={{ fontSize: 22, marginLeft: 16 }}>{marketing.replace(".json", "")}</span>
                </Grid>
                <Grid item className={classes.btns}>
                  <Button style={{ margin: 8 }} color="primary" size="small" variant="contained" onClick={() => command(marketing.replace(".json", ""), "start")}>
                    {"Start"}
                  </Button>

                  <Button style={{ margin: 8 }} color="tertiary" size="small" variant="contained" onClick={() => command(marketing.replace(".json", ""), "stop")}>
                    {"Stop"}
                  </Button>
                  <Button style={{ margin: 8 }} color="tertiary" size="small" onClick={() => command(marketing.replace(".json", ""), "log")}>
                    {"Visualizar Log"}
                  </Button>
                  <Button style={{ margin: 8 }} color="secondary" size="small" onClick={() => command(marketing.replace(".json", ""), "delete")}>
                    {"Delete"}
                  </Button>
                </Grid>

              </Grid>

            </>

          )
          )
          }
        </div>
        <div id='logsAntigos' style={{ display: 'none' }} >

          <Box marginRight={3}>
            <Button color="primary" variant="contained" onClick={() => voltar()} >
              voltar
            </Button>
          </Box>
          {logs.map(marketing => (
            <>
              <hr></hr>

              <Grid container spacing={12}>
                <Grid item >
                  <span style={{ fontSize: 22, marginLeft: 16 }}>{marketing.replace(".txt", "")}</span>
                </Grid>
                <Grid item className={classes.btns}>

                  <Button style={{ margin: 8 }} color="tertiary" size="small" onClick={() => command(marketing.replace(".txt", ""), "logOld")}>
                    {"Visualizar Log"}
                  </Button>
                  <Button style={{ margin: 8 }} color="secondary" size="small" onClick={() => command(marketing.replace(".txt", ""), "deleteLog")}>
                    {"Delete Log"}
                  </Button>
                </Grid>

              </Grid>
            </>

          ))}

        </div>

        <div id='form' >
          <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}
          validationSchema={CampanhaSchema}
          >
            {({
              values, touched, errors, isSubmitting, 

              handleChange,

              handleSubmit,

            }) => (
              <form style={{ padding: "16px" }} onSubmit={handleSubmit}>

                <Grid container spacing={12}>
                  <Grid item >
                    <StyledInputNumber
                      select
                      label="Tipo"
                      size="small"
                      id="inputField"
                      variant="outlined"
                      name="tipo"
                      //value={typeMessage}
                      required
                      onChange={setTypeMessage}
                      title="Defina o tipo de mensagem que deseja enviar"
                    >
                      {msgType.map(msgType => (
                        <MenuItem key={msgType.id} value={msgType} style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                          {msgType}
                        </MenuItem>
                      ))}
                    </StyledInputNumber>
                  </Grid>
                  <Grid item >
                    <StyledInput
                      size="small"
                      name="nomeCampanha"
                      label="Nome da Campanha"
                      variant="outlined"
                      title="Defina um nome para a campanha"
                      placeholder="promoção x y z"
                      required="Insira o nome da campanha"
                      error={touched.nomeCampanha && Boolean(errors.nomeCampanha)}
										  helperText={touched.nomeCampanha && errors.nomeCampanha}
                      value={values.nomeCampanha}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item >
                    <StyledInput
                      select
                      label="Fila"
                      size="small"
                      id="inputField"
                      variant="outlined"
                      name="fila"
                      title="Para Efetuar o disparo é necessário informar a Fila que será atribuído os tickets da campanha"
                      error={touched.fila && Boolean(errors.fila)}
										  helperText={touched.fila && errors.fila}
                      value={selectedQueueIds}
                      onChange={handleChange}
                    >
                      {queues.map(queue => (
                        <MenuItem key={queue.id} value={queue.id} style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                          {queue.name}
                        </MenuItem>
                      ))}
                    </StyledInput>
                  </Grid>
                  <Grid item >
                    <StyledInput
                      select
                      label="Usuário"
                      size="small"
                      id="inputField"
                      variant="outlined"
                      name="user"
                      title="Para Efetuar o disparo é necessário informar o Usuário que será atribuído os tickets da campanha"
                      required
                      value={selectedUserIds}
                      onChange={handleChange}
                    >
                      {users.map(user => (
                        <MenuItem key={user.id} value={user.id} style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </StyledInput>
                  </Grid>

                  <Grid item>
                    <StyledInputNumber
                      select
                      label="T mínimo"
                      size="small"
                      id="inputField"
                      variant="outlined"
                      name="mintime"
                      title="Para Efetuar o disparo é necessário setar um tempo mínimo e máximo em segundos para o envio de mensagens o tempo mínimo muito baixo pode acarretar em banimento da sua conta do WhatsApp"
                      required
                      value={selectedTmin}
                      onChange={handleChange}
                    >
                      {tmins.map(tmin => (
                        <MenuItem key={tmin.id} value={tmin} style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                          {tmin}
                        </MenuItem>
                      ))}
                    </StyledInputNumber>
                  </Grid>
                  <Grid item xs={1.2}>
                    <StyledInputNumber
                      select
                      label="T máximo"
                      size="small"
                      id="inputField"
                      variant="outlined"
                      name="maxtime"
                      title="Para Efetuar o disparo é necessário setar um tempo mínimo e máximo em segundos para o envio de mensagens o tempo mínimo muito baixo pode acarretar em banimento da sua conta do WhatsApp"
                      required
                      value={selectedTmax}
                      onChange={handleChange}
                    >
                      {tmaxs.map(tmax => (
                        <MenuItem key={tmax.id} value={tmax} style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                          {tmax}
                        </MenuItem>
                      ))}
                    </StyledInputNumber>
                  </Grid>
                  <Grid item >
                    <StyledInputNumber
                      select
                      label="Intervalo"
                      size="small"
                      id="inputField"
                      variant="outlined"
                      name="interval"
                      required
                      value={selectedInterval}
                      onChange={handleChange}
                      title="Este será o tempo entra os seu contato receber a saudação e a mensagem"
                    >
                      {intervals.map(interval => (
                        <MenuItem key={interval.id} value={interval} style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                          {interval}
                        </MenuItem>
                      ))}
                    </StyledInputNumber>
                  </Grid>


                </Grid>
                <Box mb={5} mt={2}>
                  <Grid item mb={5}>

                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={connections}
                      //onChange={(event, newValue) => { selectedConnections = newValue }}
                      onChange={(e, newValue) => {
                        const connId = newValue ? newValue.id : '';
                        setSelectedConn({ ...selectedConn, connId });
                        setCurrentConn(newValue ? newValue : "");
                      }}

                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          name="Conexões"
                          label="Conexões"
                          fullWidth
                          title="Selecione conexões para o disparo das mensagens, não é possível fazer disparos com a conexão Default"
                          placeholder="Selecione conexões para o disparo das mensagens, não é possível fazer disparos com a conexão Default"
                        />
                      )}
                    />

                    <div className={classes.formdisplay}>
                      <div className={classes.formtextarea}>
                        <Paper className={classes.formpaper}>
                          <TextField
                            id="numbers"
                            name="numbers"
                            required
                            value={values.numbers || ""}
                            onChange={handleChange}
                            label="Números"
                            variant="outlined"
                            fullWidth
                            multiline={true}
                            rows={30}
                            margin="dense"
                            placeholder="553588754197&#13;&#10;553588754197&#13;&#10;553588754197&#13;&#10;553588754197" />
                        </Paper>
                      </div>
                      <div className={classes.messagearea}>
                        <Paper className={classes.formpaper}>
                          <TextField
                            className={classes.formtextarea}
                            id="saudacao1"
                            name="saudacao1"
                            value={values.saudacao1 || ""}
                            onChange={handleChange}
                            label="Saudação 1"
                            variant="outlined"
                            required="required"
                            fullWidth
                            multiline
                            margin="dense"
                            placeholder="Olá, tudo bem?&#13;&#10;Bom dia tudo bem?&#13;&#10;Oi você esta bem ?" />
                          {typeMessage?.target?.value === "Texto" && (<TextField
                            className={classes.formtextarea}
                            id="message1"
                            name="message1"
                            value={values.message1 || ""}
                            onChange={handleChange}
                            label="Mensagem 1"
                            variant="outlined"
                            required="required"
                            fullWidth
                            multiline
                            margin="dense"
                            placeholder="Tenho um produto que vai te ajudar a resolver todos os seus problemas!&#13;&#10;Estamos recrutando pessoas para um determinado evento!&#13;&#10;Abraços, a gente se vê!" />)}
                          {typeMessage?.target?.value === "Mídia" && (
                            <>
                              <input
                                type="file"
                                id="message1"
                                accept=".png, .jpg, .pdf, .mp4, .mp3, .ogg"
                                className={classes.uploadInput}
                                onChange={handleChangeMedias}
                              />
                              <label htmlFor="message1">
                                <IconButton
                                  aria-label="upload"
                                  component="span"

                                >
                                  <AttachFileIcon className={classes.sendMessageIcons} />
                                </IconButton>
                              </label>
                              {media1[0] ? <div> </div> : <label> Adicionar mensagem 1</label>}
                              {media1[0]?.type.includes("image") ?
                                <div className={classes.attach}><div>Mensagem 1</div><span>{media1[0]?.name}</span>
                                  <img className={classes.img} src={URL.createObjectURL(media1[0])} alt="" width="150px" height="150px" border-radius="5px" /></div> : ""}
                              {media1[0]?.type.includes("pdf") ?
                                <div className={classes.attach} ><div>Mensagem 1</div><span>{media1[0]?.name}</span>
                                  <img className={classes.img} src="./logoPDF.png" alt="" width="150px" height="150px" border-radius="5px" /></div> : ""}
                              {media1[0]?.type.includes("video") ?
                                <div className={classes.attach} ><div>Mensagem 1</div><span>{media1[0]?.name}</span>
                                  <video className={classes.messageMedia} src={URL.createObjectURL(media1[0])} controls width="150px" height="150px" border-radius="5px" /></div> : ""}
                              {media1[0]?.type.includes("audio") ?
                                <div className={classes.attach} ><div>Mensagem 1</div><span>{media1[0]?.name}</span>
                                  <audio controls><source src={URL.createObjectURL(media1[0])} type="audio/ogg"></source></audio></div> : ""}
                              {!media1[0] ? <img src="./upload.png" alt="" width="150px" height="150px"></img> : ""}





                            </>
                          )}

                        </Paper>
                        <Paper className={classes.formpaper}>
                          <TextField
                            className={classes.formtextarea}
                            id="saudacao2"
                            name="saudacao2"
                            value={values.saudacao2 || ""}
                            onChange={handleChange}
                            label="Saudação 2"
                            variant="outlined"
                            required="required"
                            fullWidth
                            multiline
                            margin="dense"
                            placeholder="Olá, tudo bem?&#13;&#10;Bom dia tudo bem?&#13;&#10;Oi você esta bem ?" />
                          {typeMessage?.target?.value === "Texto" && (<TextField
                            className={classes.formtextarea}
                            id="message2"
                            name="message2"
                            value={values.message2 || ""}
                            onChange={handleChange}
                            label="Mensagem 2"
                            variant="outlined"
                            required="required"
                            fullWidth
                            multiline
                            margin="dense"
                            placeholder="Tenho um produto que vai te ajudar a resolver todos os seus problemas!&#13;&#10;Estamos recrutando pessoas para um determinado evento!&#13;&#10;Abraços, a gente se vê!" />)}
                          {typeMessage?.target?.value === "Mídia" && (
                            <>
                              <input
                                type="file"
                                id="message2"
                                accept=".png, .jpg, .pdf, .mp4, .mp3, .ogg"
                                className={classes.uploadInput}
                                onChange={handleChangeMedias}
                              />
                              <label htmlFor="message2">
                                <IconButton
                                  aria-label="upload"
                                  component="span"

                                >
                                  <AttachFileIcon className={classes.sendMessageIcons} />
                                </IconButton>
                              </label>
                              {media2[0] ? <div> </div> : <label> Adicionar mensagem 1</label>}
                              {media2[0]?.type.includes("image") ?
                                <div className={classes.attach}><div>Mensagem 2</div><span>{media2[0]?.name}</span>
                                  <img className={classes.img} src={URL.createObjectURL(media2[0])} alt="" width="150px" height="150px" border-radius="5px" /></div> : ""}
                              {media2[0]?.type.includes("pdf") ?
                                <div className={classes.attach} ><div>Mensagem 2</div><span>{media2[0]?.name}</span>
                                  <img className={classes.img} src="./logoPDF.png" alt="" width="150px" height="150px" border-radius="5px" /></div> : ""}
                              {media2[0]?.type.includes("video") ?
                                <div className={classes.attach} ><div>Mensagem 2</div><span>{media2[0]?.name}</span>
                                  <video className={classes.messageMedia} src={URL.createObjectURL(media2[0])} controls width="150px" height="150px" border-radius="5px" /></div> : ""}
                              {media2[0]?.type.includes("audio") ?
                                <div className={classes.attach} ><div>Mensagem 2</div><span>{media2[0]?.name}</span>
                                  <audio controls><source src={URL.createObjectURL(media2[0])} type="audio/ogg"></source></audio></div> : ""}
                              {!media2[0] ? <img src="./upload.png" alt="" width="150px" height="150px"></img> : ""}





                            </>
                          )}
                        </Paper>
                        <Paper className={classes.formpaper}>
                          <TextField
                            className={classes.formtextarea}
                            id="saudacao3"
                            name="saudacao3"
                            value={values.saudacao3 || ""}
                            onChange={handleChange}
                            label="Saudação 3"
                            variant="outlined"
                            required="required"
                            fullWidth
                            multiline
                            margin="dense"
                            placeholder="Olá, tudo bem?&#13;&#10;Bom dia tudo bem?&#13;&#10;Oi você esta bem ?" />
                          {typeMessage?.target?.value === "Texto" && (<TextField
                            className={classes.formtextarea}
                            id="message3"
                            name="message3"
                            value={values.message3 || ""}
                            onChange={handleChange}
                            label="Mensagem 3"
                            variant="outlined"
                            required="required"
                            fullWidth
                            multiline
                            margin="dense"
                            placeholder="Tenho um produto que vai te ajudar a resolver todos os seus problemas!&#13;&#10;Estamos recrutando pessoas para um determinado evento!&#13;&#10;Abraços, a gente se vê!" />)}
                          {typeMessage?.target?.value === "Mídia" && (
                            <>
                              <input
                                type="file"
                                id="message3"
                                accept=".png, .jpg, .pdf, .mp4, .mp3, .ogg"
                                className={classes.uploadInput}
                                onChange={handleChangeMedias}
                              />
                              <label htmlFor="message3">
                                <IconButton
                                  aria-label="upload"
                                  component="span"

                                >
                                  <AttachFileIcon className={classes.sendMessageIcons} />
                                </IconButton>
                              </label>
                              {media3[0] ? <div> </div> : <label> Adicionar mensagem 1</label>}
                              {media3[0]?.type.includes("image") ?
                                <div className={classes.attach}><div>Mensagem 3</div><span>{media3[0]?.name}</span>
                                  <img className={classes.img} src={URL.createObjectURL(media3[0])} alt="" width="150px" height="150px" border-radius="5px" /></div> : ""}
                              {media3[0]?.type.includes("pdf") ?
                                <div className={classes.attach} ><div>Mensagem 3</div><span>{media3[0]?.name}</span>
                                  <img className={classes.img} src="./logoPDF.png" alt="" width="150px" height="150px" border-radius="5px" /></div> : ""}
                              {media3[0]?.type.includes("video") ?
                                <div className={classes.attach} ><div>Mensagem 3</div><span>{media3[0]?.name}</span>
                                  <video className={classes.messageMedia} src={URL.createObjectURL(media3[0])} controls width="150px" height="150px" border-radius="5px" /></div> : ""}
                              {media3[0]?.type.includes("audio") ?
                                <div className={classes.attach} ><div>Mensagem 3</div><span>{media3[0]?.name}</span>
                                  <audio controls><source src={URL.createObjectURL(media3[0])} type="audio/ogg"></source></audio></div> : ""}
                              {!media3[0] ? <img src="./upload.png" alt="" width="150px" height="150px"></img> : ""}





                            </>
                          )}
                        </Paper>


                        <div>



                          Aceito os Termos
                          <Checkbox
                            required
                            label="Aceito os termos"
                            name="Termos"
                          />
                          <Box>
                            Estou ciente da possibilidade de sanções por parte do WhatsApp, esta ferramenta pode causar bloqueio parcial do número e até mesmo suspensão definitiva, caso seja usado em excesso seu uso pode ser considerado como spam.
                          </Box>
                          <Box>
                            <br></br>
                            Recomendações
                            <li>Não enviar arquivos com tamanhos superior a 16mb</li>
                            <br></br>
                            Formatos de envios suportados
                            <li>pdf - jpg - png - mp4 - mp3 - ogg</li>
                          </Box>

                        </div>

                        <Box mt={3}>
                          <Button color="primary" variant="contained" type="submit">
                            Criar Campanha
                          </Button>
                        </Box>
                        <Box mt={3}>
                          <Button color="tertiary" variant="contained" onClick={viewLogsAntigos}>
                            Visualizar Logs Antigos
                          </Button>
                        </Box>

                      </div>
                    </div>
                  </Grid>
                </Box>



              </form>
            )}
          </Formik>





        </div>
        <div id="log" style={{ display: 'none' }} className={classes.log} >
          <Box marginRight={3}>
            <Button color="primary" variant="contained" onClick={() => voltar()} >
              voltar
            </Button>
          </Box>

          <plaintext>
            {log}
          </plaintext>

        </div>



      </Card>
    </InvoiceRoot>
  );
};

const tmins = [
  '1',
  '2',
  '3',
]
const tmaxs = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10']

const intervals = [
  '5',
  '10',
  '20',
  '30',
  '40',
  '50',
  '60',
]

const msgType = [
  'Texto',
  'Mídia',
]

const initialValues = {

  saudacao1: "Olá, tudo bem?",
  saudacao2: "O que pretende fazer hoje?",
  saudacao3: "Siga nossos informativos",
  message1: "",
  message2: "",
  message3: "",
  numbers:
    `5547911111111
5547911111112
5547911111113
`
};




export default MassMessagesForm;
