import React, { useState, useEffect } from "react";
import openSocket from "socket.io-client";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import { isArray, capitalize } from "lodash";
import { FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},

	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,

	},

	settingOption: {
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},

}));
const Settings = () => {
	const initialQueue = {
		id: 0,
		name: "Fila Default Api não selecionada"
	}
	const cons = (e) => {
		if (e) {
			return JSON.parse(e)
		} else {
			return initialQueue
		}}
	const classes = useStyles();
	const [settings, setSettings] = useState([]);
	const [queues, setQueues] = useState(initialQueue);
	const [currentQueue, setCurrentQueue] = useState();
	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings");
				setSettings(data);

				const { data: queueList } = await api.get('/queue')
				let customQueueList = queueList.map((c) => ({ id: c.id, name: c.name }));
				if (isArray(customQueueList)) {
					setQueues([{ id: "", name: "" }, ...customQueueList]);
				}
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, []);
	const getSettingValue = key => {
		const { value } = settings.find(s => s.key === key);
		return value;
	};
	const handleChangeSetting = async e => {
		const selectedValue = JSON.stringify(e);
		const settingKey = 'queueDefaultApi';
		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
			window.location.reload()
		} catch (err) {
			toastError(err);
		}
	};
	return (
		<div className={classes.root}>
			<Container className={classes.container} maxWidth="sm">
				<Paper className={classes.paper}>
					<TextField
						id="api-token-setting"
						readonly
						label="Token Api"
						margin="dense"
						variant="outlined"
						fullWidth
						value={settings && settings.length > 0 && getSettingValue("userApiToken")}
					/>
				</Paper>
				<FormControl
					variant="outlined"
					fullWidth
				>
					<Autocomplete
						fullWidth
						value={settings && settings.length > 0 && cons(getSettingValue("queueDefaultApi"))}
						options={queues}
						required
						onChange={(e, queue) => {
							handleChangeSetting(queue ? queue : initialQueue)
						}}
						getOptionLabel={(option) => option.name}
						getOptionSelected={(option, value) => {
							return value.id === option.id
						}}
						renderInput={(params) => <TextField {...params} variant="outlined" label="Fila Default Api" placeholder="Fila Default Api" />}
					/>
				</FormControl>
			</Container>
		</div>
	);
};

export default Settings;
