import React from "react";

import Helmet from "react-helmet"





export const Header = (props) => {
    const openCount = props.openCount;
    const pendingCount = props.pendingCount;
    if (pendingCount) {
        let faviconPending = pendingCount;
            if(pendingCount > 9) faviconPending = 10;
        return <Helmet>
            <title>{pendingCount.toString()} Aguardando</title>
            <meta name="theme-color" content="#ffff66"></meta>
            {/* {'/red' + pendingCount.toString() + '1-filled-48.ico'} */}
            <link rel="icon" type="image/png" href={`/red/${faviconPending.toString()}-filled-48.ico`} sizes="16x16" ></link>
        </Helmet>;
    }

    else if (openCount) {
        let faviconOpen = openCount;
            if(openCount > 9) faviconOpen = 10;

        return <Helmet>
            
            <title>{openCount.toString()} Atendendo</title>
            <meta name="theme-color" content="#66ff33"></meta>
            <link rel="icon" type="image/png" href={`/green/${faviconOpen.toString()}-filled-48.ico`} sizes="16x16" ></link>
        </Helmet>;
    }else 
    if(!openCount && !pendingCount){
    return <Helmet>
        <title>Nada de mensagens</title>
        <meta name="Nada de mensagens" />
        <link rel="icon" type="image/png" href="/favicon.ico" sizes="16x16" />
    </Helmet>;

    }

}







